import { twMerge } from 'tailwind-merge';

import useSegmentAnalytics from '@/hooks/useSegmentAnalytics';
import { getComponentText } from '@/utils/getComponentText';

export function Button({
  children,
  className,
  disabled,
  onClick,
  type,
  variant = 'default',
  ...props
}: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>) {
  const { trackEvent } = useSegmentAnalytics();

  const buttonStyle = {
    light: 'button-light',
    medium: 'button-medium',
    dark: 'button-dark',
    alert: 'button-alert',
    default: 'button-dark',
  };

  const extendedOnClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    const buttonText = getComponentText(children);
    trackEvent('cta_tapped', { copy: buttonText });

    if (onClick) onClick(e);
  };

  return (
    <button
      {...props}
      className={twMerge(buttonStyle[variant], className)}
      disabled={disabled}
      type={type || 'button'}
      onClick={extendedOnClick}
    >
      {children}
    </button>
  );
}

interface ButtonProps {
  children?: React.ReactNode;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  variant?: 'light' | 'medium' | 'alert' | 'default' | undefined;
  className?: string;
}
