import { Children, isValidElement, ReactElement, ReactNode } from 'react';

const hasChildren = (
  element: ReactNode,
): element is ReactElement<{ children: ReactNode | ReactNode[] }> =>
  isValidElement<{ children?: ReactNode[] }>(element) &&
  Boolean(element.props.children);

const childToString = (child?: ReactNode): string => {
  if (!child || typeof child === 'boolean') return '';
  if (typeof child === 'object' && Object.keys(child).length === 0) return '';
  return String(child);
};

export const getComponentText = (children: ReactNode | ReactNode[]): string => {
  if (!(children instanceof Array) && !isValidElement(children)) {
    return childToString(children);
  }

  return Children.toArray(children)
    .map(child => {
      if (hasChildren(child)) {
        return getComponentText(child.props.children);
      }
      if (isValidElement(child)) {
        return '';
      }
      return childToString(child);
    })
    .join('');
};
