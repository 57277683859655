import { JSX, SVGProps } from 'react';

export const CaretIcon = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>,
) => (
  <svg
    fill='none'
    height={24}
    width={20}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path d='M0 2h20v20H0z' fill='#fff' fillOpacity={0.01} />
    <path
      clipRule='evenodd'
      d='M8.243 7.576a.6.6 0 0 1 .848 0l4 4a.6.6 0 0 1 0 .848l-4 4a.6.6 0 1 1-.848-.848L11.818 12 8.243 8.424a.6.6 0 0 1 0-.848Z'
      fill='#101D46'
      fillRule='evenodd'
    />
  </svg>
);
